import { GetServerSidePropsContext } from 'next';
import React, { ReactNode } from 'react';

import { PageDataProps } from '@tb-core/adapters/contentful/graphql/webpage-adapter';
import { ArticleType } from '@tb-core/helpers/contentful/article-list-props-util';
import { Store } from '@tb-core/types';
import { ContentfulCollection } from 'contentful';

export interface PageContent {
    pageData: Omit<PageDataProps, 'topicMetadataCollection'>;
}

export interface ArticleListProps
    extends ContentfulCollection<{
        title: string;
        subtitle: string;
        slug: string;
        type: ArticleType;
        content: any;
        categoryCollection?: ArticleCategoriesProps;
    }> {}

interface ArticleCategoriesProps
    extends ContentfulCollection<{
        label: string;
        value: string;
        articleType?: string;
    }> {}

export interface PageContextProps {
    content: PageContent;
    ctx: Partial<Omit<GetServerSidePropsContext, 'req' | 'res'>> & {
        url: string;
    };
    notFound?: boolean;
    storeData?: Store;
    articleList?: ArticleListProps;
    articleCategories?: ArticleCategoriesProps;
}

export interface PageProviderProps extends PageContextProps {
    children: ReactNode;
}

/* First we will make a new context */
const PageContext = React.createContext<PageContextProps | undefined>(
    undefined
);
const { Provider } = PageContext;

const PageProvider = ({ children, ...props }: PageProviderProps) => (
    <Provider value={props}>{children}</Provider>
);

export default PageProvider;
export { PageContext };
