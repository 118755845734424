import * as amplitude from '@amplitude/analytics-browser';
import { webAttributionPlugin } from '@amplitude/plugin-web-attribution-browser';
import { ContentfulMetaDataUserTags } from '@tb-core/constants/contentful';
import { amplitudeKey, webFixedVersion } from '@tb-core/helpers/next-env';

export const setAmplitudeUserStatus = (isLoggedIn?: boolean) =>
    typeof isLoggedIn !== 'undefined'
        ? {
              user_status: isLoggedIn
                  ? ContentfulMetaDataUserTags.USER_LOGGED_IN
                  : ContentfulMetaDataUserTags.USER_GUEST
          }
        : {};

export const amplitudeInitialize = async (userId?: string) => {
    amplitude.add(webAttributionPlugin());
    amplitude.init(amplitudeKey, userId, {
        appVersion: webFixedVersion,
        disableCookies: true,
        logLevel: 2
    });

    return amplitude;
};
