import { CSCItemModifierOptions } from '@tb-core/constants/client-side-cart';
import { StylePLU } from '@tb-core/constants/products';
import {
    CustomizationOption,
    CustomizationOptions
} from '@tb-core/types/products';

export const styleTypesMap: { [key: string]: CSCItemModifierOptions } = {
    [StylePLU.SUPREME_STYLE]: CSCItemModifierOptions.SUPREME_PRODUCT,
    [StylePLU.FRESCO_STYLE]: CSCItemModifierOptions.FRESCO_PRODUCT,
    [StylePLU.GRILLED_STYLE]: CSCItemModifierOptions.GRILLABLE_PRODUCT
};

/**
 * Updates the code of the first variant option of a specific style product
 * to the parents code. (Ex: 2001 -> 2001Main) and returns the style product
 * instead of returning addonOptions
 * @param customizationOptions - The customization options from the product JSON.
 * @param styleProductKey - The key for the specific style in customizationOptions.
 * @returns An array containing the updated style product.
 */
export const updateStyleOptionCategory = (
    customizationOptions: CustomizationOptions,
    styleProductKey:
        | CSCItemModifierOptions.FRESCO_PRODUCT
        | CSCItemModifierOptions.SUPREME_PRODUCT
): CustomizationOption[] => {
    const styleOption = customizationOptions?.[styleProductKey];

    if (styleOption && styleOption?.code && styleOption.variantOptions?.[0]) {
        styleOption.variantOptions[0].code = styleOption.code;
        return [styleOption] as CustomizationOption[];
    }

    return [];
};
