import { parseBoolean } from '@tb-core/helpers/utils/parse-boolean';

export const amplitudeKey = process.env.AMPLITUDE_KEY || '';
export const amplitudeKeyProd = process.env.AMPLITUDE_KEY_PROD || '';
export const assetPath = process.env.ASSET_PATH || '';
export const basePath = process.env.BASE_PATH || '';
export const brazeEnabled = process.env.BRAZE_ENABLED || '';
export const brazeHost = process.env.BRAZE_HOST || '';
export const brazeKey = process.env.BRAZE_KEY || '';
export const checkoutApiTimeout = process.env.CHECKOUT_API_TIMEOUT || '';
export const contentfulEnv = process.env.CTF_ENV || '';
export const contentfulHost = process.env.CTF_HOST || '';
export const contentfulSpace = process.env.CTF_SPACE_ID || '';
export const contentfulToken = process.env.CTF_TOKEN || '';
export const cscEnabled: boolean = parseBoolean(process.env.CSC_ENABLED || '');
export const dataDogEnv = process.env.DATA_DOG_ENV || '';
export const dataPath = process.env.DATA_PATH || '';
export const envId = process.env.ENV_ID || '';
export const facebookAppId = process.env.FACEBOOK_APP_ID || '';
export const firebaseApiKey = process.env.FIREBASE_API_KEY || '';
export const firebaseAppId = process.env.FIREBASE_APP_ID || '';
export const firebaseAuthDomain = process.env.FIREBASE_AUTH_DOMAIN || '';
export const firebaseDBUrl = process.env.FIREBASE_DB_URL || '';
export const firebaseMeasurementId = process.env.FIREBASE_MEASUREMENT_ID || '';
export const firebaseMessagingSenderId =
    process.env.FIREBASE_MESSAGING_SENDER_ID || '';
export const firebaseProjectId = process.env.FIREBASE_PROJECT_ID || '';
export const firebaseStorageBucket = process.env.FIREBASE_STORAGE_BUCKET || '';
export const googleGaId = process.env.GA_ID || '';
export const googleGaGlobalId = process.env.GA_GLOBAL_ID || '';
export const googleMapsUrl = process.env.GOOGLE_MAPS_URL || '';
export const googlePayAuthMethods = process.env.GOOGLE_PAY_AUTH_METHODS || '';
export const googlePayEnv = process.env.GOOGLE_PAY_ENV || '';
export const googlePayGatewayMerchantId =
    process.env.GOOGLE_PAY_GATEWAY_MERCHANT_ID || '';
export const googlePayGatewayName = process.env.GOOGLE_PAY_GATEWAY_NAME || '';
export const googlePayMerchantId = process.env.GOOGLE_PAY_MERCHANT_ID || '';
export const googlePayMerchantName = process.env.GOOGLE_PAY_MERCHANT_NAME || '';
export const googlePayMethod = process.env.GOOGLE_PAY_PAYMENT_METHOD || '';
export const googlePaySupportedCards =
    process.env.GOOGLE_PAY_SUPPORTED_CARDS || '';
export const googleTagManagerId = process.env.GOOGLE_TAG_MANAGER_ID || '';
export const host = process.env.API_HOST || '';
export const invalidCartProducts = process.env.INVALID_CART_PRODUCTS || '';
export const optimizelySdkKey = process.env.OPTIMIZELY_SDK_KEY || '';
export const nextApiBasePath = process.env.NEXT_API_BASE_PATH || '';
export const nextApiHost = process.env.NEXT_API_HOST || '';
export const recaptchaSitekey = process.env.RECAPTCHA_SITE_KEY || '';
export const routes: any[] = JSON.parse(process.env.ROUTES || '[]');
export const serverlessApiUrl = process.env.SERVERLESS_API_URL || '';
export const serviceBasePath = process.env.SERVICE_BASE_PATH || '';
export const serviceUrl = process.env.SERVICE_URL || '';
export const shopifyDomain = process.env.SHOPIFY_DOMAIN || '';
export const shopifyToken = process.env.SHOPIFY_TOKEN || '';
export const storeNationalId = process.env.STORE_NATIONAL_ID || '';
export const subscribeEmailApiKey = process.env.SUBSCRIBE_EMAIL_API_KEY || '';
export const subscribeEmailApiUrl = process.env.SUBSCRIBE_EMAIL_API_URL || '';
export const tacoGifterProducts = process.env.TACO_GIFTER_PRODUCTS || '';
export const tbApiHost = process.env.TB_API_HOST || '';
export const testingBearerToken = process.env.TESTING_BEARER_TOKEN || '';
export const urlScheme = process.env.URL_SCHEME || '';
export const webFixedVersion = process.env.WEB_FIXED_VERSION || '';
export const webOrigin = process.env.WEB_ORIGIN || '';
export const yumAuth = process.env.YUM_AUTH_URL || '';
export const yumOidcClientId = process.env.YUM_OIDC_CLIENT_ID || '';
export const yumOidcClientIdPasswordless =
    process.env.YUM_OIDC_CLIENT_ID_PASSWORDLESS || '';
export const yumOidcClientName = process.env.YUM_OIDC_CLIENT_NAME || '';
export const yumOidcEnvironment = process.env.YUM_OIDC_ENVIRONMENT || '';

export const applePayCertificatePassword =
    process.env.APPLE_PAY_CERTIFICATE_PASSWORD || '';
export const applePayCertificatePath =
    process.env.APPLE_PAY_CERTIFICATE_PATH || '';
export const applePayCountryCode = process.env.APPLE_PAY_COUNTRY_CODE || '';
export const applePayDisplayName = process.env.APPLE_PAY_DISPLAY_NAME || '';
export const applePayMerchantCapabilities =
    process.env.APPLE_PAY_MERCHANT_CAPABILITIES || '';
export const applePayMerchantId = process.env.APPLE_PAY_MERCHANT_ID || '';
export const isLoyaltyLifetimePointsEnabled: boolean = parseBoolean(
    process.env.LOYALTY_LIFETIME_POINTS_ENABLED || ''
);
