export const arrangeMagicLinkUrl = '/api/userAuth/v1/idp/arrange-magic-link';
export const cartUrl = '/api/cart';
export const checkGiftCardBalanceUrl =
    '/tacobellwebservices/v3/tacobell/users/current/my-account/payment-details/giftcard/balance';
export const contactUsSubmitUrl = '/support/case';
export const contactUsSubmitv2Url = '/supportcase/v2/support/case';
export const contentfulDeliveryUrl =
    '/spaces/{space}/environments/{environment}/{target}?access_token={accessToken}&content_type={type}';
export const contentfulArticlesApiUrl = '/api/articles/get';
export const contentfulGraphicQlUrl = '/api/contentful/graphql';
export const currentDatetimeUrl = '/ecom/v1/promotions/get-current-date-time';
export const customerNotificationsUrl = '/notificationPreferences';
export const deleteFavoriteItemApiUrl = '/favorites/v1/products/{favProductId}';
export const deleteGuestOrderByGuidUrl =
    '/tacobellwebservices/v2/tacobell/users/anonymous/order/{guid}';
export const deleteOrderByIdUrl =
    '/tacobellwebservices/v2/tacobell/users/current/order/{orderCode}';
export const devProxyUrl = '/api/dev-proxy{url}';
export const directionsUrl =
    'https://www.google.com/maps/dir/{fromCoordinates}/{toCoordinates}/';
export const favoritesApiUrl = '/favorites/v1/products';
export const favoritesApiSharedCustomizedProduct =
    '/favorites/v1/products/shared?customizedProduct={customizedProduct}';
/** @deprecated use `locationsGeopoint` instead */
export const geoCodeUrl = '/tbGeocodeService';
export const getCreditAndGiftCardsUrl =
    '/tacobellwebservices/v2/tacobell/users/current/my-account/payment-details?allGiftCards={allGiftCards}';
export const getCustomerUrl = '/tacobellwebservices/v2/tacobell/users';
export const getGiftCardBalance =
    '/tacobellwebservices/v2/tacobell/users/anonymous/my-account/payment-details/giftcard/{giftCardNumber}/balance/?pin={giftCardPin}';
export const getGuestOrderByGuidUrl =
    '/tacobellwebservices/v2/tacobell/users/anonymous/orders/{guid}';
export const getUserOrdersUrl =
    '/tacobellwebservices/v2/tacobell/users/current/orders';
export const getOrderByCodeUrl =
    '/tacobellwebservices/v2/tacobell/users/current/orders/{orderCode}';
export const giftCardAddGuestUserUrl =
    '/tacobellwebservices/v2/tacobell/users/{guestUserId}/carts/{cartId}/paymentdetails/giftcard';
export const giftCardAddLoggedInUserUrl =
    '/tacobellwebservices/v2/tacobell/users/current/carts/{cartCode}/paymentdetails/giftcard';
export const giftCardAddToUserAccountUrl =
    '/tacobellwebservices/v2/tacobell/users/current/my-account/payment-details/giftcard';
export const googleMapsSearchUrl =
    'https://www.google.com/maps/search/{latitude},{longitude}/';
export const locationGeopointApi = '/location/v1/{query}';
export const logoutUrl = '/api/delete-auth-cookies';
export const loyaltyActivateUserUrl = '/acceptTerms';
export const loyaltyDashboardUrl = '/loyalty/v1/dashboard';
export const menuUrl = '/api/menu/{storeId}';
export const microserviceCartUrl = '/ecom/v1/cart';
export const miniCartUrl = '/api/mini-cart';
export const microserviceTrackerUrl = '/submit-order/v1/';
export const nextContentfulGraphicQlUrl =
    'https://graphql.contentful.com/content/v1/spaces/{space}/environments/{environment}';
export const nextLoyaltyActivateUserUrl = '/api/loyalty-activate-user';
export const nextLoyaltyDashboardUrl = '/api/loyalty-dashboard';
export const nextMenuUrl =
    '/tacobellwebservices/v2/tacobell/products/menu/{storeId}';
export const nextMiniCartUrl = '/cart/miniCart/SUBTOTAL';
export const nextProductUrl =
    '/tacobellwebservices/v2/tacobell/products/{productId}/{storeId}';
export const nextStoreUrl = '/tacobellwebservices/v2/tacobell/stores/{storeId}';
export const nextUserUrl = '/userHeaderInfo?store={store}';
export const paymentMethodGuestUserUrl =
    '/tacobellwebservices/v2/tacobell/users/{guestUserId}/carts/{cartCode}/versioned/paymentdetails/?paymentDetailsId={cardId}';
export const paymentMethodLoggedInUserUrl =
    '/tacobellwebservices/v2/tacobell/users/current/carts/{cartCode}/versioned/paymentdetails/?paymentDetailsId={cardId}';
export const creditCardGuestUserNonceUrl =
    '/tacobellwebservices/v2/tacobell/users/{guestUserId}/carts/{cartCode}/paymentdetails/creditcard/nonce';
export const creditCardLoggedInUserNonceUrl =
    '/tacobellwebservices/v2/tacobell/users/current/carts/{cartCode}/paymentdetails/creditcard/nonce';
export const myAccountCreditCardNonceUrl =
    '/tacobellwebservices/v2/tacobell/users/current/my-account/payment-details/creditcard/nonce';
export const pickupTimeGuestUserUrl =
    '/tacobellwebservices/v2/tacobell/users/anonymous/carts/{cartId}/pickuptime/{pickupTimeStamp}';
export const pickupTimeLoggedInUserUrl =
    '/tacobellwebservices/v2/tacobell/users/current/carts/{cartCode}/pickuptime/{pickupTimeStamp}';
/** @deprecated use `locationsGeopoint` instead */
export const placeDetailsUrl = '/tbPlaceDetails';
export const processApplePayUrl =
    '/tacobellwebservices/v4/tacobell/users/{currentOrGuestUserId}/carts/{cartId}/processApplePay';
export const processGooglePayUrl =
    '/tacobellwebservices/v4/tacobell/users/{currentOrGuestUserId}/carts/{cartId}/processGooglePay';
export const productUrl = '/api/products/{productId}/{storeId}';
export const productsUrl = '/search/autocomplete/SearchBox?term={term}';
export const promoCodeUrl = '/loyalty/v1/promotion/{code}';
export const removeCreditCardUrl =
    '/tacobellwebservices/v2/tacobell/users/current/my-account/payment-details/creditcard/{creditCardId}';
export const removeGiftCardUrl =
    '/tacobellwebservices/v2/tacobell/users/current/my-account/payment-details/giftcard/{giftCardId}';
export const roundupUrl =
    '/tacobellwebservices/v2/tacobell/users/{guestUserId}/carts/{cartId}/roundUp/{enabled}';
export const setDefaultCreditCardUrl =
    '/tacobellwebservices/v2/tacobell/users/current/my-account/payment-details/set-default/{paymentId}';
export const storePickupTimeUrl =
    '/tacobellwebservices/v2/tacobell/storesPickUpTime/{storeId}';
export const storesLookupUrl =
    '/tacobellwebservices/v2/tacobell/stores?latitude={lat}&longitude={long}&_={timestamp}';
export const storesPageUrl = 'https://locations.tacobell.com/{storeId}';
export const storeUrl = '/api/store/{storeId}';
export const submitOrderGuestUserUrl =
    '/tacobellwebservices/v2/tacobell/users/{guestUserId}/order/?cartId={cartGuid}';
export const submitOrderLoggedInUserUrl =
    '/tacobellwebservices/v2/tacobell/users/current/order/?cartId={cartCode}';
export const userOmniChannelUrl = '/tacobellwebservices/v2/tacobell/users';
export const unsubscribeUrl = '/w/{email}/m/e';
export const userUrl = '/api/user';
export const yumOidcAuthUrl = '/api/userAuth/v1/oidc';
export const yumOidcLogoutUrl =
    '/api/userAuth/v1/idp/oidc/session/end?id_token_hint={idToken}&post_logout_redirect_uri={redirectUri}';
export const yumPaymentSessionConfigUrl = '/ecom/v1/payments/session-config';
