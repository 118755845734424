export const cartPageUrl = '/order/cart';
export const contactUsThankYouPageUrl = '/contact-us/thank-you';
export const checkoutPageUrl = '/order/checkout';
export const checkoutPageYumUrl = '/order/yum/checkout';
export const favoritesUrl = '/food/favorites';
export const foodPageUrl = '/food';
export const foodProductCategoryUrl = '/food/[cateogry]/[product]';
export const foodStorePageUrl = '/food?store={storeId}';
export const locationsPageUrl = '/locations';
export const orderTrackingPageUrl = '/checkout/orderConfirmation/';
export const orderTrackingPageYumUrl = '/order/yum/tracker';
export const profileOrderTrackingPageUrl = '/profile/order/{guid}';
export const recentOrdersUrl = '/profile/orders';
