import {
    CSCItemModifierOptions,
    CSCItemModifierType
} from '@tb-core/constants/client-side-cart';
import { calculateIncludedCalorieDifference } from '@tb-core/helpers/products/calories';
import { getVariant } from '@tb-core/helpers/products/variant';
import {
    CustomizationOption,
    CustomizationOptions,
    StyleOption,
    StyleOptionModifier,
    VariantOption
} from '@tb-core/types/products';

interface CustomizationOptionType {
    customizationOption: CustomizationOption;
    type: CSCItemModifierOptions;
}

interface VariantOptionType {
    type: CSCItemModifierOptions;
    variantCodes: string[];
    variantOption: VariantOption;
}

// Determines if the provided key is a customization
// to be included in modifying an object (styles like Supreme and Fresco
// should not be included, as they need to pull from these).
const isCustomizationOption = (key: string) =>
    [
        CSCItemModifierOptions.ADDON,
        CSCItemModifierOptions.INCLUDE,
        CSCItemModifierOptions.PROTEIN,
        CSCItemModifierOptions.SAUCE,
        CSCItemModifierOptions.UPGRADE
    ].includes(key as CSCItemModifierOptions);

/**
 * Given a modifierType, constructs a new array of VariantOptionType to be assigned to a style.
 * This constructs the add and remove products that will determine what modifiers get added to
 * a Product when selecting a Style. Some attributes need to be added manually, including
 * variantCodes to help see the other variants of a modifier, and adjusted calories showing the
 * difference between the modifier and the main included modifier product
 *
 * @param customizationOptions - All customizationOptions for a Product consolidated in one array.
 * @param modifierType - The modifierType (e.g. Add, Remove) to be collected in the new VariantOptionType array
 * @returns A new VariantOptionType array to help with selecting Styles on the Product.
 */
const mapCustomizationsByModifierType = (
    customizationOptions: CustomizationOptionType[],
    modifierType: CSCItemModifierType
): VariantOptionType[] => {
    const toReturn: VariantOptionType[] = [];
    for (const { customizationOption, type } of customizationOptions) {
        const matchedVariant = getVariant(customizationOption, modifierType);
        if (matchedVariant) {
            let calories = matchedVariant.calories;
            let accurateCalorie = matchedVariant.accurateCalorie;

            // If modifierType is not ADD, need to calculate the calorie
            // difference from the main modifier variant
            if (modifierType !== CSCItemModifierType.ADD) {
                const {
                    accurateCalorieDiff,
                    caloriesDiff
                } = calculateIncludedCalorieDifference(
                    accurateCalorie,
                    calories,
                    customizationOption
                );
                calories = caloriesDiff;
                accurateCalorie = accurateCalorieDiff;
            }
            toReturn.push({
                type,
                variantCodes: customizationOption.variantOptions.map(
                    variant => variant.code
                ),
                variantOption: {
                    ...matchedVariant,
                    accurateCalorie,
                    calories
                }
            });
        }
    }
    return toReturn;
};

/**
 * Function to populate the Add and Remove StyleOptionModifier arrays on a product.
 * Loops through the modifierCodes, searches for a matching customizationOption, and adds
 * the matched option to a new StyleOptionModifier array. This constructs the modifier data
 * in a way that makes it easier to parse during Style selections.
 *
 * @param modifierCodes - String array of the Add or Remove products for a Style option sent from Hybris.
 * @param addRemoveOptions - A filtered list of product variant options containing only Add or Remove variants.
 * @returns A new StyleOptionModifier array of Add or Remove modifiers to help with selecting product Styles.
 */
const constructAddRemoveProducts = (
    modifierCodes: string[] | undefined,
    addRemoveOptions: VariantOptionType[]
) => {
    const addRemoveProducts: StyleOptionModifier[] = [];
    modifierCodes?.forEach(modifierCode => {
        const optionMatch = addRemoveOptions.find(
            option => option.variantOption?.code === modifierCode
        );
        if (optionMatch?.variantOption) {
            addRemoveProducts.push({
                accurateCalorie: optionMatch.variantOption.accurateCalorie,
                calories: optionMatch.variantOption.calories,
                code: optionMatch.variantOption.code,
                opt: optionMatch.type,
                price: optionMatch.variantOption.priceData?.value || 0,
                variantCodes: optionMatch.variantCodes
            });
        }
    });
    return addRemoveProducts;
};

/**
 * Sets up the StyleOption array used to track Add and Remove items from
 * a Product. The Add and Remove items are sent from the server as a plu string array, so this
 * restructures the items as a Product array to make it easier when customizing Styles on an item.
 *
 * @param options - the customizationOptions from the product JSON
 * @return styleList - an array of StyleOption objects containing a style Product and its Add and Remove
 *                     items.
 */
const StylesAdapter = (options: CustomizationOptions) => {
    const styleList: StyleOption[] = [];
    const customizationOptions: CustomizationOptionType[] = [];

    // Populate and map the CustomizationOptions by type to
    // make filtering the Add and Remove products per style easier.
    for (const [key, option] of Object.entries(options)) {
        if (isCustomizationOption(key)) {
            (option as CustomizationOption[])?.forEach(opt => {
                customizationOptions.push({
                    customizationOption: opt,
                    type: key as CSCItemModifierOptions
                });
            });
        }
    }

    // Filter Add and Remove Variant Options to populate the
    // below style options' Add and Remove products.
    const toAddOptions = mapCustomizationsByModifierType(
        customizationOptions,
        CSCItemModifierType.ADD
    );
    const toRemoveOptions = mapCustomizationsByModifierType(
        customizationOptions,
        CSCItemModifierType.MINUS
    );

    // Add Supreme Style if present
    if (options.supremeProduct?.variantOptions?.length) {
        const tempProduct: StyleOption = JSON.parse(
            JSON.stringify(options.supremeProduct)
        );

        // Populate Add and Remove products
        tempProduct.addProducts = constructAddRemoveProducts(
            options.supremeAdd,
            toAddOptions
        );

        tempProduct.removeProducts = constructAddRemoveProducts(
            options.supremeRemove,
            toRemoveOptions
        );
        tempProduct.opt = CSCItemModifierOptions.SUPREME_PRODUCT;
        styleList.push(tempProduct);
    }

    // Add Fresco Style if present
    if (options.frescoProduct?.variantOptions?.length) {
        const tempProduct: StyleOption = JSON.parse(
            JSON.stringify(options.frescoProduct)
        );

        // Populate Add and Remove products
        tempProduct.addProducts = constructAddRemoveProducts(
            options.frescoAdd,
            toAddOptions
        );

        tempProduct.removeProducts = constructAddRemoveProducts(
            options.frescoRemove,
            toRemoveOptions
        );
        tempProduct.opt = CSCItemModifierOptions.FRESCO_PRODUCT;
        styleList.push(tempProduct);
    }
    // @TODO: These will be added individually during each sprint.
    /*
    if (vegetarianProduct?.variantOptions?.length) {
        const tempProduct = JSON.parse(JSON.stringify(vegetarianProduct));
        tempProduct.addProducts = vegetarianAdd;
        tempProduct.removeProducts = vegetarianRemove;
        tempProduct.opt = CSCItemModifierOptions.VEGETARIAN_PRODUCT;
        styleList.push(tempProduct);
    }
    */

    // Add Grilled Style if present.
    if (options.grillableProduct?.variantOptions?.length) {
        const tempProduct: StyleOption = JSON.parse(
            JSON.stringify(options.grillableProduct)
        );
        tempProduct.opt = CSCItemModifierOptions.GRILLABLE_PRODUCT;
        tempProduct.addProducts = [];
        tempProduct.removeProducts = [];
        styleList.push(tempProduct);
    }

    return styleList;
};

export default StylesAdapter;
