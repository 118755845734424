import {
    CustomizationOption,
    ModifierType,
    VariantOption
} from '@tb-core/types/products';

export const getVariant = (
    option: CustomizationOption,
    modifierType: ModifierType
) =>
    option.variantOptions?.find(
        variantOption =>
            variantOption?.modifierType.toLowerCase() ===
            modifierType.toLowerCase()
    );

/**
 * Searches for a customization or variant option object with a
 * specific property value within a nested structure.
 *
 * @param {Record<string, any>} options - The options object containing nested structures to search through.
 * @param {keyof CustomizationOption | keyof VariantOption} propertyName - The name of the property to match.
 * @param {string} propertyValue - The value of the property to match.
 * @returns {CustomizationOption | null} - The first object that matches the property name and value
 */
export const findVariantOptionWithProperty = (
    options: Record<string, any>,
    propertyName: keyof CustomizationOption | keyof VariantOption,
    propertyValue: string
): CustomizationOption | null => {
    let result: CustomizationOption | VariantOption | null = null;

    const searchProperty = (obj: any) => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (typeof obj[key] === 'object') {
                    searchProperty(obj[key]); // Recursive call for nested objects or arrays
                } else if (key === propertyName && obj[key] === propertyValue) {
                    result = obj;
                    return; // Return as soon as the first match is found
                }
            }
        }
    };

    searchProperty(options);

    return result;
};

/**
 * Searches for a variant option by code and returns the key containing it.
 *
 * @param {Object.<string, any>} obj - The object containing arrays of products.
 * @param {string} code - The code of the variant option to search for.
 * @returns {string} - The key containing the variant option if found, otherwise null.
 */
export const findOptKeyByCode = (obj: Record<string, any>, code: string) => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const products = obj[key];
            if (Array.isArray(products)) {
                for (const product of products) {
                    if (product?.variantOptions) {
                        const variantOption = product.variantOptions.find(
                            (option: VariantOption) => option.code === code
                        );
                        if (variantOption) {
                            return key;
                        }
                    }
                }
            }
        }
    }
    return null;
};
