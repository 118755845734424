import { CSSProperties, RefObject, SyntheticEvent } from 'react';

export interface SafeAnchorProps extends Record<string, any> {
    children?: JSX.Element | JSX.Element[] | string;
    className?: string;
    href: string;
    label?: string;
    onClick?: (event: SyntheticEvent<HTMLAnchorElement, MouseEvent>) => void;
    ref?: RefObject<HTMLInputElement>;
    role?: string;
    style?: CSSProperties;
    target?: string;
}

const IsExternal = (href: string) => {
    return href && href.toLowerCase().indexOf('http') >= 0;
};

const SafeAnchor = ({
    children,
    href,
    ref,
    target,
    ...props
}: SafeAnchorProps) => {
    const isExternalLink = IsExternal(href);
    const uniqueAttr =
        props.label !== undefined ? props.label : encodeURIComponent(href);

    return (
        <a
            {...props}
            data-id={`safe-anchor-${uniqueAttr}`}
            href={href}
            target={target || (isExternalLink ? '_blank' : '_self')}
            ref={typeof ref === 'function' ? ref : () => {}}
            rel={isExternalLink ? 'noreferrer noopener' : ''}
        >
            {children}
        </a>
    );
};

export default SafeAnchor;
