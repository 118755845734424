import React, { useEffect, useState } from 'react';

import { setStoreIdCookie } from '@tb-core/helpers/browser/cookie';
import { storeNationalId } from '@tb-core/helpers/next-env';
import {
    GetSelectedStore,
    GetStoreData,
    localStorage
} from '@tb-core/helpers/storage';
import { getQueryStoreId } from '@tb-core/helpers/store';
import getStore from '@tb-core/providers/get-store';
import { JsxChildren, Store } from '@tb-core/types';

interface StoreProviderProps {
    children: JsxChildren;
    initialStore?: Store;
}
const StoreContext = React.createContext<Store | undefined>(undefined);
const { Consumer, Provider } = StoreContext;

const StoreProvider = ({ children, initialStore }: StoreProviderProps) => {
    const [stateStoreData, setStateStoreData] = useState<Store>(
        initialStore?.storeId ? initialStore : { storeId: '' }
    );

    /**
     * Rules of precedence from highest to lowest:
     * 1. storeId from URL param
     * 2. storeId from last getStore call ( cached in local storage ) or from Hybris
     * 3. SSR storeData store id if not national
     */
    const fetchStore = async () => {
        const queryStoreId = getQueryStoreId(window.location.search);
        const localStoreData = GetStoreData();

        const currentStoreId =
            queryStoreId || localStoreData?.storeId || stateStoreData?.storeId;

        if (localStoreData && currentStoreId === localStoreData.storeId) {
            // storeId unchanged, then use last fetched response
            setStateStoreData(localStoreData);
            setStoreIdCookie(localStoreData?.storeId || '');
        } else if (currentStoreId && currentStoreId !== storeNationalId) {
            const storeData = await getStore(currentStoreId);
            if (storeData.success) {
                localStorage.setItems({ storeData });
                setStateStoreData(storeData);
                setStoreIdCookie(storeData?.storeId || '');
            }
        } else {
            setStateStoreData({
                storeId: storeNationalId
            });
        }

        // Verify the updated store ID matches the localStorage
        // selectedStore ID. If not, remove the selected store.
        // Pickup methods between stores differ, so the user will
        // be required to reselect their choice.
        const selectedStoreId = GetSelectedStore()?.storeId;
        if (selectedStoreId !== localStoreData?.storeId) {
            window.localStorage.removeItem('selectedStore');
        }
    };
    useEffect(() => {
        fetchStore();
    }, []);

    return <Provider value={stateStoreData}>{children}</Provider>;
};

const StoreConsumer = Consumer;

export default StoreProvider;
export { StoreConsumer, StoreContext };
